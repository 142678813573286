.nav-bar {
  background-image: linear-gradient(90deg, rgb(57, 191, 151) 0%, rgb(57, 191, 151) 100%);
  margin-top: 80px; /* 헤더 높이만큼 margin을 줘서 아래에 위치하도록 설정 */
  display: flex;
  align-items: center;
  justify-content: center; /* 중앙 정렬 */
  height: 50px;
  padding: 10px 20px;
  position: relative;
  z-index: 999;
}

.nav-link {
  color: #ffffffb3 !important; 
  font-weight: bold !important; 
  font-size: 16px;
  line-height: 19px;
  margin: 0 20px; /* 중앙 정렬을 위해 좌우 간격 조정 */
  padding: 8px 10px;
  position: relative;
  text-decoration: none;
}

.nav-link:hover,
.nav-link:focus {
  color: #ffffffb3; /* Hover 시에도 흰색 유지 */
}

.nav-tab {
  display: block;
}

.shop-link-wrapper {
  margin-left: 0; /* 모든 링크를 중앙에 배치하기 위해 좌측 여백 제거 */
}

.shop-link {
  color: #ffffffb3; /* 글씨를 흰색으로 설정 */
}
