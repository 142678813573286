.community-list {
    margin: 0;
    padding: 0;
  }
  
  .community-list-title {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 16px;
  }
  
  .community-card-box {
    position: relative;
    width: 208px; /* 카드의 가로 사이즈 */
    height: 280px; /* 카드의 세로 사이즈 */
    border-radius: 14px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 0 #00000005;
    margin: 0 10px; /* 카드가 가로로 중앙 정렬되도록 설정 */
  }
  
  .community-card-cover-wrap {
    width: 100%;
    height: 70%; /* 이미지 영역의 높이 */
    overflow: hidden;
  }
  
  .community-card-cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지 비율 유지 */
  }
  
  .community-card-logo-wrap {
    position: absolute;
    top: 60%; /* 커버 이미지 하단에서 로고가 위치할 비율 */
    left: 50%;
    transform: translateX(-50%); /* 중앙 정렬 */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #fff;
    z-index: 2; /* 로고가 커버 이미지 위에 있도록 설정 */
  }
  
  .community-card-logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .new-amsterdam-regular {
    font-family: "New Amsterdam", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .community-card-caption {
    position: absolute;
    top: 70%; /* 커버 이미지 바로 아래부터 시작 */
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    background: rgba(60, 60, 60, 0.8); /* 캡션 배경색 */
    color: #fff;
    text-align: center;
    box-sizing: border-box; /* 패딩을 포함하여 너비 계산 */
    z-index: 1; /* 캡션이 로고 아래에 있도록 설정 */
  
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    line-height: 1; /* 줄 간격을 줄여서 텍스트 간 간격을 줄임 */
  }
  
  /* Col의 너비를 고정하고 여백을 제거 */
  .fixed-col {
    max-width: 208px; /* 카드의 가로 사이즈와 동일 */
    flex: 0 0 208px; /* 고정 너비 */
    width: 100%; /* 100% 너비 */
  }
  
  /* Row의 기본 간격을 제거 */
  .row {
    margin: 0;
  }
  
  .row > .col {
    padding: 0;
  }
  
  