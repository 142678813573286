.media-container {
  padding: 0px 20px 20px 20px;

  .media-detail-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .media-title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }

    .media-files {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .media-file {
        width: 600px; /* 가로 크기를 고정합니다 */
        margin: 10px 0; /* 세로 여백 */
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        img, video {
          width: 100%; /* 부모 요소의 너비를 채우도록 설정 */
          height: auto; /* 비율에 맞춰 높이 조절 */
          border-radius: 8px;
          object-fit: cover;
        }
      }
    }

    .media-content {
      font-size: 1.2rem;
      line-height: 1.5;
      margin-bottom: 20px;
      text-align: left;
    }

    button {
      display: block;
      margin: 0 auto;
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .upload-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .upload-label {
      background-color: #007bff;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }

    .upload-input {
      display: none;
    }
  }

  .media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 40px;

    .media-item {
      cursor: pointer;
      text-align: center;
      position: relative;

      &:hover {
        transform: none;
      }

      img,
      .no-thumbnail {
        width: 100%;
        height: 250px; /* 높이를 고정하여 통일감 유지 */
        border-radius: 8px;
        object-fit: cover; /* 부모 요소의 크기에 맞게 잘림 */
      }

      p {
        margin-top: 8px;
        font-size: 1rem;
        font-weight: bold;
      }

      .thumbnail-edit-label {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.8rem;

        .thumbnail-edit-input {
          display: none;
        }
      }
    }
  }

  .floating-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}
