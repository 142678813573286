.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-title {
    text-align: center; // 제목을 가운데 정렬
    margin-bottom: 20px; // 제목 아래 여백 추가
    margin-top: 20px;
  }

.video-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 비율 유지 */
  background-color: #000;
  overflow: hidden;
  margin: 0 auto;

  .fullscreen-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
}

.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// 대형 화면 (1200px 이상) 대응 스타일
@media (min-width: 1200px) {
  .live-streaming-container {
    width: 70%; // 큰 화면에서는 70% 너비 사용
  }
}
