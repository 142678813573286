.merchandise-list {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 16px;
    color: #333;
    text-align: center;
  }

  .category-section {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 48px;
    position: relative; // 화살표 버튼 위치 설정을 위해 추가
  }

  .merchandise-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .merchandise-items {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr); // 한 줄에 4개의 상품
    gap: 24px;
    width: 100%;
  }

  li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    transition: box-shadow 0.3s ease;
    width: 100%;
    min-height: 350px;

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .image-container {
      width: 100%;
      padding-top: 100%; /* 1:1 비율 */
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      margin-bottom: 12px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    p {
      font-size: 1rem;
      margin: 8px 0;

      &:nth-child(2) {
        font-weight: bold;
        color: #333;
      }

      &:nth-child(3) {
        color: #007aff;
        font-size: 1.1rem;
      }
    }
  }

  .slider-button {
    background-color: #007aff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .prev-button {
    left: -40px;
  }

  .next-button {
    right: -40px;
  }
}
