.right-sidebar {
  position: fixed; // Make the sidebar fixed in the viewport
  top: 50%; // Align it to the middle of the viewport vertically
  transform: translateY(-50%); // Center the element vertically
  right: 20px; // Distance from the right edge of the screen
  width: 300px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-height: 80%; // Optional: restrict height if there is too much content
  overflow-y: auto; // Optional: Add scroll for overflow content inside the sidebar
}

.right-sidebar-profile-card {
  text-align: center;
  margin-bottom: 20px;
}

.right-sidebar-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.right-sidebar-profile-info h2 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
}

.right-sidebar-membership {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  p {
    margin-bottom: 10px; // 텍스트 아래에 간격 추가
  }
  .join-button {
    margin-top: 10px; // 버튼 위에 간격 추가
  }
}

.right-sidebar-join-button,
.right-sidebar-dm-button {
  background-color: #ff7e86;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.right-sidebar-dm-section {
  text-align: center;
  margin-bottom: 20px;
}

.right-sidebar-user-info {
  text-align: center;
  margin-bottom: 20px;
}

.overlay {
  position: fixed; /* 화면 전체를 기준으로 고정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 반투명 */
  display: flex;
  justify-content: center; /* 가로 가운데 정렬 */
  align-items: center; /* 세로 가운데 정렬 */
  z-index: 1000; /* 다른 콘텐츠 위에 표시 */
}

.nickname-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px; /* 모달 너비 조정 */
  text-align: center;
  position: relative; /* position을 relative로 설정 */
}

.nickname-modal h2 {
  margin-bottom: 20px;
}

.nickname-modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #007bff;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #ccc;
  color: #333;
}
