.sign-up-topmargin {
  margin-top: 50px;
}

.signup-goto-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.main-signup-b {
  color: rgb(142, 142, 142);
  text-decoration: none;
}
