.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-title {
  text-align: center; // 제목을 가운데 정렬
  margin-bottom: 10px; // 제목 아래 여백 추가
  margin-top: 10px;
}

// 이미지 사용을 위한 추가된 부분
.live-indicator {
  display: inline-block;
  width: 50px; // 이미지의 너비
  height: 50px; // 이미지의 높이
  margin-right: 10px;
  vertical-align: middle; // 텍스트와 수직으로 맞춤
}

.live-chat-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.video-section {
  flex: 1;
  max-width: 68%; // 비디오 섹션의 너비를 68%로 줄임
  margin-left: 5%; // 비디오와 채팅 박스 사이에 간격 추가
  margin-bottom: 50px;
}

.chat-container {
  width: 40%; // 채팅 박스의 너비를 27%로 넓힘
  height: 72vh; // 비디오 섹션과 동일한 높이로 설정
  margin-right: 5%;
  max-width: 350px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #87ceeb;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
}

.chat-box {
  display: flex;
  flex-direction: column;
  height: 93%; // 원하는 높이로 조정 가능
  background-color: #f9f9f9;
}

.messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.message {
  padding: 8px 10px;
  margin-bottom: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.chat-input-container {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
}

.chat-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  box-sizing: border-box;
}

.send-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #76b2d4;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; // 16:9 비율 유지
  background-color: #000;
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // 상단 정렬
  width: 100%;
}

.video-section {
  flex: 1;
  max-width: 65%; // 비디오 섹션의 너비를 68%로 줄임
  margin-right: 10px; // 비디오와 채팅 박스 사이의 간격을 20px로 설정
  position: relative;
  height: 75vh; // 비디오 섹션의 높이를 화면의 75%로 고정
}

.fullscreen-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
