.media-container {
  padding: 0px 20px 20px 20px;

  .media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 40px;

    .media-item {
      cursor: pointer;
      text-align: center;
      position: relative;

      &:hover {
        transform: none;
      }

      img,
      .no-thumbnail {
        width: 100%;
        height: 250px; /* 높이를 고정하여 통일감 유지 */
        border-radius: 8px;
        object-fit: cover; /* 부모 요소의 크기에 맞게 잘림 */
      }

      .live-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .live-indicator {
        display: inline-block;
        width: 50px; // 이미지의 너비
        height: 50px; // 이미지의 높이
        margin-right: 10px;
        margin-bottom: 10px;
        vertical-align: middle; // 텍스트와 수직으로 맞춤
      }

      p {
        margin-top: 8px;
        padding-top: 3px;
        font-size: 1rem;
        font-weight: bold;
      }

      .thumbnail-edit-label {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.8rem;

        .thumbnail-edit-input {
          display: none;
        }
      }
    }
  }

  .floating-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}
