.cart-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  //background-color: #f9f9f9;

  h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }

  .empty-cart {
    text-align: center;
    padding: 50px;
    font-size: 18px;
    color: #666;
  }

  .cart-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 100px;
  }

  .cart-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative; // Remove button position absolute
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }

    img.thumbnail {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 8px;
      border: 1px solid #ddd;
    }

    .item-details {
      flex: 1;
      margin-left: 20px;

      h3 {
        font-size: 20px;
        margin-bottom: 8px;
        color: #333;
      }

      p {
        margin: 4px 0;
        font-size: 16px;
        color: #555;
      }
    }

    .remove-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: none;
      color: black;
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #ffffffb4;
      }
    }

    .quantity-control {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: auto;

      button {
        background-color: rgba(57, 191, 151, 0.5);
        color: white;
        border: none;
        padding: 5px 10px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgb(57, 191, 151);
        }
      }

      span {
        font-size: 16px;
        color: #333;
      }
    }
  }

  .cart-total {
    margin-top: 20px;
    text-align: right;

    h3 {
      font-size: 24px;
      color: #333;
    }

    .checkout-btn {
      background-color: rgb(252, 2, 105);
      color: white;
      border: none;
      padding: 12px 24px;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-top: 20px;

      &:hover {
        background-color: rgb(180, 5, 78);
      }
    }
  }
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
    position: relative; // Remove absolute positioning of the remove button

    .item-details {
      margin-left: 0;
      margin-top: 15px;
    }

    img.thumbnail {
      width: 100%;
      height: auto;
    }

    .remove-btn {
      position: static;
      margin-top: 10px;
    }
  }

  .cart-total {
    text-align: center;

    h3 {
      font-size: 20px;
    }
  }
}
